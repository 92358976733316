import { useLazyQuery } from "@apollo/client";
import Loader from "react-loader-spinner";
import {
  colors,
  constants,
  GetUserContext,
  gql,
  React,
  styled,
  useContext,
  useEffect,
  useMutation,
  useQuery,
  useState,
} from "../../imports";
import { GetCurrentPage } from "../../localStorage/Store";
import InfoComponent from "../InfoComponent";
import StyledButton from "../StyledButton";
import CreateListComp from "./CreateListComp";
import DisplayListComp from "./DisplayListComp";
import DurationAlert from "./DurationAlert";
import EditListComp from "./EditListComp";
import LinkAlert from "./LinkAlert";
import NotifyAlert from "./NotifyAlert";
import UserSavedLists from "./UserSavedListsComp";
import useWindowSize from "../hooks/useWindowSize";

const CreateListMutation = gql`
  mutation MyMutation($name: String!) {
    __typename
    createInviteList(input: { clientMutationId: "InviteList", name: $name }) {
      inviteLists {
        name
        id
        suborderers {
          email
          firstName
          lastName
          rowId
        }
      }
    }
  }
`;

const GetURLParam = gql`
  query getSuborderKey {
    suborderKey
  }
`;

const AddPersonToListMutation = gql`
  mutation MyMutation($suborderers: [InviteRowInput]!, $id: String!) {
    __typename
    addSuborderersToList(
      input: { clientMutationId: "", suborderers: $suborderers, listId: $id }
    ) {
      inviteLists {
        id
        name
        suborderers {
          email
          firstName
          lastName
          rowId
        }
      }
      succeeded
    }
  }
`;
const GetInviteListsQuery = gql`
  query getInviteListsquery {
    __typename
    inviteLists {
      id
      name
      suborderers {
        email
        firstName
        lastName
        rowId
      }
    }
  }
`;
const RemoveInviteListMutation = gql`
  mutation MyMutation2($id: String!) {
    __typename
    deleteInviteList(input: { clientMutationId: "", listId: $id }) {
      inviteLists {
        id
        name
        suborderers {
          email
          firstName
          lastName
          rowId
        }
      }
    }
  }
`;
const RemovePersonFromListMutation = gql`
  mutation MyMutation2($listId: String!, $rowID: Int!) {
    __typename
    removeSubordererFromList(
      input: {
        clientMutationId: "removeSuborderer"
        listId: $listId
        subordererRowId: $rowID
      }
    ) {
      inviteLists {
        id
        name
        suborderers {
          email
          firstName
          lastName
          rowId
        }
      }
    }
  }
`;
const InviteSuborderersFromListQuery = gql`
  query MyQuery($array: [String!]) {
    __typename
    inviteSuborderersFromLists(listIds: $array)
  }
`;

const RenameListMutation = gql`
  mutation MyMutation($id: String!, $listName: String!) {
    __typename
    updateListName(
      input: { clientMutationId: "", listId: $id, listName: $listName }
    ) {
      inviteLists {
        id
        name
        suborderers {
          email
          firstName
          lastName
          rowId
        }
      }
    }
  }
`;
const GetUserQuery = gql`
  query MyQuery($id: ID!) {
    __typename
    user(id: $id) {
      invitedLists {
        fieldGroupName
        invitedlists {
          listid
          fieldGroupName
        }
      }
    }
  }
`;
const SetDurationMutation = gql`
  mutation MyMutation($duration: Int!) {
    __typename
    setAddProductsUntil(
      input: { addProductsUntil: $duration, clientMutationId: "" }
    ) {
      newTime
    }
  }
`;
const InviteList = ({ activeView, parentPage }) => {
  const [user, setUser] = useContext(GetUserContext);
  //Show Edit list Mode
  const [editModeState, setEditModeState] = useState(false);
  //The id of the clicked List
  const [editListIdState, setEditListIDState] = useState(false);
  //should my inviteList render
  const [renderInviteLists, setRenderInviteLists] = useState(false);
  //the people in this list
  const [personsInListState, setPersonsInListState] = useState([]);
  //Already invited Lists in this order
  const [invitedLists, setInvitedLists] = useState([]);
  //All Lists the user have
  const [allInviteListsOfUser, setAllInviteListsOfUser] = useState([]);
  //the oldOptionsProperty for the Multiselect
  const [oldOptionsAllInviteListsOfUser, setoldOptionsAllInviteListsOfUser] =
    useState();
  const [setDurationOrder, { loadingMutation, errorMutation }] =
    useMutation(SetDurationMutation);
  //the State where all selected lists are stored
  const [selectedInviteListsState, setSelectedInviteLists] = useState([]);
  const { durationFromBackend, setDuration, setDurationFromBackend } =
    useContext(GetCurrentPage);

  const [listName, setListName] = useState("");
  const [type, setType] = useState("");
  const [showLinkAlert, setShowLinkAlert] = useState(false);
  const [showNotifyAlert, setShowNotifyAlert] = useState(false);
  const [showDurationAlert, setShowDurationAlert] = useState(false);
  const [inviteLink, setInviteLink] = useState("");
  const [tmpDuration, setTmpDuration] = useState();
  const [loadingNotify, setLoadingNotify] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inviteError, setInviteError] = useState("");
  const size = useWindowSize();

  const [
    getAllInviteLists,
    { loading: loading1, error: error1, data: allListsResponse, refetch },
  ] = useLazyQuery(GetInviteListsQuery);

  const [
    GetUserInvitedLists,
    {
      loading: loading2,
      error: error2,
      data: userResponse,
      refetch: refetchUserData,
    },
  ] = useLazyQuery(GetUserQuery, {
    variables: { id: user.id },
    fetchPolicy: "network-only",
  });
  const [
    InviteSuborderersFromList,
    { loading: loading4, error: error4, data: inviteSuborderersFromList },
  ] = useLazyQuery(InviteSuborderersFromListQuery);

  const [CreateList, { loading: mutationLoading2, error: mutationError2 }] =
    useMutation(CreateListMutation, {
      refetchQueries: ["getInviteListsquery"],
    });
  const [
    AddPersonToList,
    { loading: mutationLoading3, error: mutationError3 },
  ] = useMutation(AddPersonToListMutation);
  const [
    UpdateListName,
    { loading: updateListNameLoading, error: updateListNameError },
  ] = useMutation(RenameListMutation);
  const [RemoveHoleList, { loading: mutationLoading4, error: mutationError4 }] =
    useMutation(RemoveInviteListMutation);
  const [
    RemovePersonFromList,
    { loading: mutationLoading5, error: mutationError5 },
  ] = useMutation(RemovePersonFromListMutation);
  const { loading3, error3, data: urlKey } = useQuery(GetURLParam);

  useEffect(() => {
    getAllInviteLists();
    // GetUserInvitedLists();
  }, []);

  useEffect(() => {
    if (allListsResponse) {
      if (!editModeState) {
        setAllInviteListsOfUser(allListsResponse.inviteLists);
        GetUserInvitedLists();
      }
    }
  }, [allListsResponse]);

  useEffect(() => {
    if (userResponse && allListsResponse) {
      let tmpArray = [];
      let tmpArray2 = [];
      if (userResponse.user.invitedLists.invitedlists) {
        userResponse.user.invitedLists.invitedlists.map((tmpItem) => {
          let tmpInvited = allListsResponse?.inviteLists.filter(
            (item) => item.id == tmpItem.listid
          );

          tmpArray.push(tmpInvited[0]);
          tmpArray2.push({ id: tmpInvited[0]?.id, name: tmpInvited[0]?.name });
        });
      }
      setInvitedLists(tmpArray);
      setoldOptionsAllInviteListsOfUser(tmpArray2);
      setRenderInviteLists(true);
      setLoading(false);
    }
  }, [userResponse]);

  const addPeronToList = (stateInput, edit) => {
    let tmp = stateInput.filter(
      (ele1) => ele1.firstName != "" && ele1.firstName != null
    );
    if (!edit) {
      let ObjArray = personsInListState.slice();
      tmp.map((item, i) => {
        let obj = {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
        };
        obj.rowId = "";
        ObjArray.push(obj);
      });
      setPersonsInListState(ObjArray);
    }

    return AddPersonToList({
      variables: {
        suborderers: tmp || "",
        id: editListIdState,
      },
    })
      .then(async (response) => {
        await refetch();
        let MyList = await response.data.addSuborderersToList.inviteLists.find(
          (element) => element.id == editListIdState
        );
        setPersonsInListState(
          MyList?.suborderers.filter((ele1) => ele1.email != "")
        );
        return true;
      })
      .catch((error) => {
        return false;
      });
  };

  useEffect(() => {
    setSelectedInviteLists([]);
  }, [editModeState]);

  const editPerson = async (deletePerson, addPerson) => {
    let newPersonState = personsInListState
      .slice()
      .filter((item) => item.rowId !== deletePerson.rowId);
    let obj = addPerson;

    obj.rowId = "";
    newPersonState.push(obj);
    setPersonsInListState(newPersonState);

    if (await removePersonFromList(deletePerson, true)) {
      let tmp = [];
      delete addPerson.rowId;
      tmp.push(addPerson);
      await addPeronToList(tmp, true);
    } else {
    }
  };
  const editSelectedList = async (id) => {
    setEditModeState(false);
    setPersonsInListState([]);
    setEditListIDState(id);

    let MyList = await allListsResponse.inviteLists.find(
      (element) => element.id == id
    );
    setPersonsInListState(MyList?.suborderers);
    setAllInviteListsOfUser([MyList]);
    setEditModeState(true);
    setListName(MyList.name);
  };

  const generateLink = () => {
    setShowLinkAlert(true);
    setInviteLink(
      "https://gutgereift.at/landingpageSubbesteller?id=" +
        encodeURIComponent(urlKey.suborderKey) +
        "&type=inviteLink"
    );
    return true;
  };
  const handleSelectInviteList = (value) => {
    setInviteError("");
    let tmpValue = value?.filter((item) => item.disabled == false);
    if (!tmpValue[0].title) {
      setSelectedInviteLists(
        selectedInviteListsState.filter((item) => item.id !== tmpValue[0].id)
      );
    } else {
      setSelectedInviteLists([...selectedInviteListsState, tmpValue[0]]);
    }
  };

  const removeList = (id) => {
    setEditListIDState("");
    setAllInviteListsOfUser(
      allListsResponse.inviteLists.filter((item) => item.id !== id)
    );
    setEditModeState(false);
    // setPersonsInListState([]);

    RemoveHoleList({ variables: { id: id } })
      .then(async (response) => {
        setAllInviteListsOfUser(response.data.deleteInviteList.inviteLists);
      })
      .catch(async (error) => {
        await refetch();
      });
  };
  const removePersonFromList = (person, edit) => {
    if (!edit) {
      let tmp = personsInListState.slice();
      setPersonsInListState(tmp.filter((item) => item.rowId != person.rowId));
    }
    return RemovePersonFromList({
      variables: { listId: editListIdState, rowID: person.rowId },
    })
      .then(async (response) => {
        if (!edit) {
          let MyList =
            await response.data.removeSubordererFromList.inviteLists.find(
              (element) => element.id == editListIdState
            );
          setPersonsInListState(
            MyList?.suborderers.filter((ele1) => ele1.email != "")
          );
        }

        // await refetch();
        return true;
      })
      .catch((error) => {
        alert(error.toString());
      });
  };
  const saveDuration = (durationParam, tmpDate) => {
    if (!durationFromBackend) {
      return setDurationOrder({ variables: { duration: durationParam } }).then(
        (response) => {
          setTmpDuration(tmpDate);
          if (type === "link") {
            return generateLink();
          } else if (type === "Invite") {
            return inviteSuborderersFromLists();
          }
        }
      );
    } else {
      if (type === "link") {
        return generateLink();
      } else if (type === "Invite") {
        return inviteSuborderersFromLists();
      }
    }
  };
  const createNewList = (newListName) => {
    CreateList({ variables: { name: newListName } })
      .then(async (response) => {
        setPersonsInListState([]);
        setSelectedInviteLists([]);
        setEditModeState(true);
        setListName(newListName);
        // setAddPeopleState(true);
        // setAllInviteListsState(response.data.createInviteList.inviteLists);
        let list = await response.data.createInviteList.inviteLists.find(
          (element) => element.name == newListName
        );
        setEditListIDState(list.id);
        setAllInviteListsOfUser([list]);
      })
      .catch((error) => {
        alert(error.toString());
      });
  };

  const renameList = (listName) => {
    let list = {
      id: allInviteListsOfUser[0]?.id,
      name: listName,
      suborderers: allInviteListsOfUser[0]?.suborderers,
    };
    setAllInviteListsOfUser([list]);

    UpdateListName({ variables: { listName: listName, id: editListIdState } })
      .then(async (response) => {
        // let list = await response.data.updateListName.inviteLists.find(
        //   (element) => element.name == listName
        // );
        // setAllInviteListsOfUser([list])
        refetch();
      })
      .catch((e) => {
        alert(e.toString());
      });
  };

  const inviteSuborderersFromLists = () => {
    setLoadingNotify(true);
    if (selectedInviteListsState.length != 0) {
      let tmp = [];
      let invitedListsTmp = [...invitedLists];
      let oldOptionsTmp = [...oldOptionsAllInviteListsOfUser];
      selectedInviteListsState.map((list) => {
        tmp.push(list.id);

        let tmpInvited = allListsResponse.inviteLists.filter(
          (item) => item.id == list.id
        );
        invitedListsTmp.push(tmpInvited[0]);
        oldOptionsTmp.push({
          id: tmpInvited[0]?.id,
          name: tmpInvited[0]?.name,
        });
      });
      setInvitedLists(invitedListsTmp);
      setoldOptionsAllInviteListsOfUser(oldOptionsTmp);
      InviteSuborderersFromList({ variables: { array: tmp } });
      setShowNotifyAlert(true);
      setSelectedInviteLists([]);
      refetchUserData();
      setLoadingNotify(false);
      return true;
      // refetch();
    } else {
      alert("keine Liste ausgewählt");
      return false;
    }
  };
  if (loading)
    return (
      <DataContainer>
        <Loader
          height={400}
          width={200}
          type="ThreeDots"
          color={colors.YellowBrigthButton}
        />
      </DataContainer>
    );

  return (
    <DataContainer>
      <Wrapper>
        <Sidebar>
          {allInviteListsOfUser.length != 0 && (
            <SmallHeading className="SmallMargin3">
              Meine gespeicherten Listen
            </SmallHeading>
          )}

          <table>
            {renderInviteLists && allInviteListsOfUser.length != 0 ? (
              <UserSavedListsWrapper>
                {allInviteListsOfUser.map((list) => {
                  return (
                    <UserSavedLists
                      parentPage={parentPage}
                      list={list}
                      editMode={editModeState}
                      oldOptions={oldOptionsAllInviteListsOfUser}
                      handleSelectInviteList={(value) =>
                        handleSelectInviteList(value)
                      }
                      editList={(listId) => editSelectedList(listId)}
                    ></UserSavedLists>
                  );
                })}
              </UserSavedListsWrapper>
            ) : (
              <div style={{ overflow: "visible" }}>
                <SmallHeading
                  className="SmallMargin2"
                  style={{ lineHeight: 1 }}
                >
                  Deine gutgereift.at Community
                </SmallHeading>
                <Paragraph style={{ width: "250px" }}>
                  Freunde können mit dem personalisierten Einladungslink einfach
                  bestellen – ohne sich zu registrieren.
                </Paragraph>
              </div>
            )}
          </table>
          {!editModeState &&
            parentPage != "einladen" &&
            allInviteListsOfUser.length != 0 && (
              <MailInviteButtonWrapper>
                <StyledButton
                  onClick={() => {
                    setInviteError("");
                    if (selectedInviteListsState.length === 0) {
                      setInviteError(
                        "Keine (weitere) Liste zum einladen ausgewählt"
                      );
                    } else {
                      setShowDurationAlert(true);
                      setType("Invite");
                    }
                  }}
                  scale={"ORDER"}
                  title={"Mail Einladungen senden"}
                  color="#B2982A"
                  fontSize={"20px"}
                  error={inviteError}
                />
                <InfoComponent
                  text="Freunde können mit dem personalisierten Einladungslink einfach bestellen – ohne sich zu registrieren."
                  paddingTop={2}
                />
              </MailInviteButtonWrapper>
            )}
          {!editModeState &&
            parentPage != "einladen" &&
            allInviteListsOfUser.length != 0 && (
              <Padding className="SmallMargin">
                <SmallHeading className="SmallMargin">
                  Gruppen Link erstellen
                </SmallHeading>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <StyledButton
                    onClick={() => {
                      // generateLink();
                      setType("link");
                      setShowDurationAlert(true);
                    }}
                    scale={"ORDER"}
                    title={"Link generieren"}
                    color="#8D7833"
                    fontSize={"20px"}
                  />
                  <InfoComponent
                    text="Informiere deine WhatsApp Kontakte oder Gruppen mit einem Einladungslink. Die Empfänger können sich nach erfolgreicher Registrierung auf gutgereift.at an deiner Bestellung beteiligen."
                    paddingTop={2}
                  />
                </div>
              </Padding>
            )}
          {!editModeState && (
            <CreateListComp
              parentPage={parentPage}
              activeView={(parentName) => {
                activeView(parentName);
              }}
              createNewList={(listName) => createNewList(listName)}
              creationLoading={mutationLoading2}
            ></CreateListComp>
          )}
        </Sidebar>
        <Content>
          {editModeState && listName ? (
            <EditListComp
              list={listName}
              removeList={() => removeList(editListIdState)}
              edit={(person, stateInput) => editPerson(person, stateInput)}
              cancel={async () => {
                setEditModeState(false);
                await refetch();
                await setAllInviteListsOfUser(allListsResponse.inviteLists);
              }}
              removePersonFromList={(person) => {
                return removePersonFromList(person, false);
              }}
              personsInList={personsInListState}
              save={async (persons, listName) => {
                await refetch();
                renameList(listName);
                return addPeronToList(persons, false);
              }}
            ></EditListComp>
          ) : (
            <TableMain>
              {allInviteListsOfUser != 0 &&
                selectedInviteListsState.length != 0 && (
                  <InputWrapper>
                    <SmallHeading2 width="60%">Liste</SmallHeading2>
                    {size.width > 1000 && (
                      <SmallHeading2 width="60%">Kontaktdaten</SmallHeading2>
                    )}
                  </InputWrapper>
                )}

              {allInviteListsOfUser.length != 0 ? (
                <>
                  {invitedLists.map((item, i) => (
                    <DisplayListComp list={item}></DisplayListComp>
                  ))}

                  {selectedInviteListsState.map((item, i) => (
                    <DisplayListComp list={item}></DisplayListComp>
                  ))}
                </>
              ) : (
                parentPage != "einladen" && (
                  <div>
                    <SmallHeading style={{ width: "250px", marginTop: 1 }}>
                      Offene Community
                    </SmallHeading>
                    <Paragraph style={{ width: "250px" }}>
                      Informiere deine WhatsApp Kontakte oder Gruppen mit einem
                      Einladungslink. Die Empfänger können sich nach
                      erfolgreicher Registrierung auf gutgereift.at an deiner
                      Bestellung beteiligen.
                    </Paragraph>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <StyledButton
                        onClick={() => {
                          // generateLink();
                          setType("link");
                          setShowDurationAlert(true);
                        }}
                        scale={"ORDER"}
                        title={"Link generieren"}
                        color="#8D7833"
                        fontSize={"20px"}
                      />
                    </div>
                  </div>
                )
              )}
            </TableMain>
          )}
        </Content>
      </Wrapper>

      {showLinkAlert && (
        <LinkAlert
          setShowAlert={() => {
            setShowLinkAlert(false);
            setDuration(tmpDuration);
            setDurationFromBackend(true);
          }}
          inviteLink={inviteLink}
        ></LinkAlert>
      )}
      {showNotifyAlert && (
        <NotifyAlert
          loading={loadingNotify}
          setShowAlert={() => {
            setShowNotifyAlert(false);
            setDuration(tmpDuration);
            setDurationFromBackend(true);
          }}
        ></NotifyAlert>
      )}
      {showDurationAlert && (
        <DurationAlert
          setShowAlert={() => setShowDurationAlert(false)}
          saveDuration={(durationParam, tmpDate) => {
            return saveDuration(durationParam, tmpDate);
          }}
        ></DurationAlert>
      )}
    </DataContainer>
  );
};

const UserSavedListsWrapper = styled.div`
  max-height: 13.5vh;
  width: 110%;
  max-width: 270px;
  /* min-height: 89px; */
  overflow-y: auto;
  overflow-x: hidden;
  /* -webkit-overflow-scrolling: touch; */
  @media (max-height: 730px) {
    max-height: 10.5vh;
  }
  @media (max-height: 676px) {
    max-height: 8.2vh;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    max-height: unset;
  }
`;
const DataContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 424px;
  @media (max-width: 1100px) {
    height: unset;
  }
  @media (max-height: 730px) {
    min-height: 384px;
  }
  @media (max-height: 676px) {
    min-height: 342px;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 60%;
    align-items: center;
  }
  @media (max-width: 540px) {
    width: 85%;
  }
`;
const TableMain = styled.div`
  width: 66%;
  margin-left: 280px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-left: unset;
    width: 100%;
  }
`;
const SmallHeading = styled.p`
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #4d4d4d;
  letter-spacing: 0.4px;
  text-align: left;
  margin-top: 0;
  padding: 0;
`;
const SmallHeading2 = styled(SmallHeading)`
  margin: 5px 0;
  width: ${(props) => props.width};
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-top: 30px;
  }
`;
const InputWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1330px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Paragraph = styled.p`
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
`;
const Padding = styled.div`
  padding-top: 20px;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-left: 30px;
  }
`;
const Sidebar = styled.div`
  position: fixed;
  z-index: 100;
  margin-right: 50px;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    position: unset;
    margin-right: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .SmallMargin2 {
    @media (max-height: 676px) {
      margin-bottom: 5px;
      padding-top: 0px;
    }
  }
  .SmallMargin3 {
    @media (max-height: 676px) {
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
  .SmallMargin {
    @media (max-height: 676px) {
      margin-bottom: 5px;
      padding-top: 10px;
    }
  }
`;
const MailInviteButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-left: 30px;
  }
`;
const Content = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1500px) {
    margin-left: unset;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export default InviteList;
