import PropTypes from "prop-types";
import React, { useState } from "react";
import { FiInfo } from "react-icons/fi";
import styled from "styled-components";

// Props: title: String (required)

// Example: <HeadingOne title="Example" />

const InfoComponent = ({ text, paddingTop }) => {
  const [hover, setHover] = useState(false);

  const startHover = () => {
    setHover(true);
  };

  const endHover = () => {
    setHover(false);
  };

  return (
    <Wrapper props_paddingTop={paddingTop}>
      <IconWrapper>
        <FiInfo
          color="#808080"
          style={{ height: "18px", width: "18px" }}
          onMouseEnter={startHover}
          onMouseLeave={endHover}
        />
      </IconWrapper>
      {hover && (
        <Description onMouseEnter={startHover} onMouseLeave={endHover}>
          {text}
        </Description>
      )}
    </Wrapper>
  );
};

InfoComponent.propTypes = {
  text: PropTypes.string.isRequired,
  paddingTop: PropTypes.number,
};

const Wrapper = styled.div`
  text-align: left;
  display: inline-block;
  margin-left: 10px;
  padding-top: ${(props) =>
    props.props_paddingTop ? props.props_paddingTop + "px" : "unset"};

  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: center;
  height: 100%;
`;

const Description = styled.div`
  position: absolute;
  margin-left: 20px;
  width: fit-content;
  max-width: 263px;
  background-color: #ffffff;
  color: #808080;
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.35;
  padding: 5px;
  box-shadow: 10px 10px 21px -4px rgba(0, 0, 0, 0.58);
  z-index: 1;
  white-space: preserve;
`;

export default InfoComponent;
