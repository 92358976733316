import { useContext } from "react";
import {
  constants,
  GetUserContext,
  React,
  styled,
  useEffect,
  useState,
} from "../../imports";
import CoolBoxInfo from "../CoolBoxInfo";
import InfoComponent from "../InfoComponent";
import "./Accordion.css";
import AccordionInitiator from "./AccordionInitiator";
import AccordionNotInitiator from "./AccordionNotInitiator";
import AccordionSingleBuyer from "./AccordionSingleBuyer";
import { gql, useQuery } from "@apollo/client";

const GetShipmentData = gql`
  query GetShipmentData {
    generalOptions {
      minimum_order_weight {
        minimumOrderWeight
        minimumOrderWeightNewCustomer
        minimumOrderWeightNewCustomerActivated
        shippingNewCustomer
      }
      shipmentCosts {
        limits {
          price
          weightLimit
        }
      }
    }
  }
`;

function Accordion({ data, parentPage, orderId, cart }) {
  const [totalCartState, setTotalCartState] = useState();
  const [shipping, setShipping] = useState();
  const [coolbox, setCoolbox] = useState();
  const [goodsValue, setGoodsValue] = useState();
  const [user] = useContext(GetUserContext);
  const { data: shipmentData } = useQuery(GetShipmentData);

  useEffect(() => {
    if (cart) {
      if (cart?.total) {
        setTotalCartState(
          cart.total.toLocaleString("DE-DE", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        );
      }

      if (cart?.shippingLines) {
        // Go here when data is from order
        const item = cart.shippingLines?.nodes?.find((ele) =>
          ele.methodTitle.includes("Versand")
        );
        const parsedEle =
          parseFloat(item?.total.replace(",", ".")) +
          parseFloat(item?.totalTax.replace(",", "."));
        setShipping(parsedEle);
      } else {
        // Go here when data is from extCard
        setShipping(cart.shipping);
      }

      if (cart?.shippingLines) {
        // Go here when data is from order
        const item = cart.shippingLines?.nodes?.find((ele) =>
          ele.methodTitle.includes("Kühlboxversand")
        );
        const parsedEle =
          parseFloat(item?.total.replace(",", ".")) +
          parseFloat(item?.totalTax.replace(",", "."));
        setCoolbox(parsedEle);
      } else {
        // Go here when data is from extCard
        setCoolbox(cart.coolbox);
      }

      if (cart?.subtotal) {
        const parsedEle =
          parseFloat(cart?.subtotal.replace(",", ".")) +
          parseFloat(cart?.cartTax.replace(",", "."));
        setGoodsValue(parsedEle);
      } else {
        setGoodsValue(cart?.subtotalWithTax);
      }
    }
  }, [cart]);
  return (
    <>
      <ListWrapper>
        <ListHeadingWrapper>
          <ListHeading className={parentPage}>
            <HeadingSmall style={{ marginTop: 0 }}>Liste</HeadingSmall>
            <HeadingSmall className={parentPage} style={{ marginTop: 0 }}>
              Preis
            </HeadingSmall>
          </ListHeading>
          {orderId &&
            !(
              data.length == 1 &&
              (data[0].orderers[0].name == "initiator" ||
                parentPage === "DataInputSubbesteller")
            ) && <HeadingSmallDM>bezahlt</HeadingSmallDM>}
        </ListHeadingWrapper>
      </ListWrapper>
      {data &&
      data.length == 1 &&
      (data[0].orderers[0].name == "initiator" ||
        parentPage === "DataInputSubbesteller") ? (
        data[0].orderers[0].products.map((product, i2) => {
          return (
            <AccordionSingleBuyer
              data={data}
              product={product}
              parentPage={parentPage}
              i2={i2}
              orderId={orderId}
              showCoolBoxIcon={parentPage !== "DataInputSubbesteller"}
              setTotalCart={(total) => {
                let tmp = parseFloat(totalCartState.replace(",", ".")) - total;
                setTotalCartState(
                  tmp.toLocaleString("DE-DE", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                );
              }}
            ></AccordionSingleBuyer>
          );
        })
      ) : data.length > 0 ? (
        data.map((list, index) => {
          return list.name != "initiator" ? (
            <AccordionNotInitiator
              data={data}
              list={list}
              parentPage={parentPage}
              index={index}
              orderId={orderId}
            ></AccordionNotInitiator>
          ) : (
            <AccordionInitiator
              data={data}
              list={list}
              index={index}
              orderId={orderId}
              parentPage={parentPage}
            ></AccordionInitiator>
          );
        })
      ) : (
        <p>Keine Artikeln im Warenkorb</p>
      )}
      {data.length > 0 && (
        <Footer className={parentPage}>
          {goodsValue > 0 && (
            <Row>
              <Paragraph>Warenwert</Paragraph>
              <Paragraph>
                €{" "}
                {goodsValue?.toLocaleString("DE-DE", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </Paragraph>
            </Row>
          )}
          {shipping > 0 && (
            <Row>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Paragraph>Versand</Paragraph>
                <InfoComponent
                  text={
                    cart.isNewCustomer && user.shipping.country === "AT"
                      ? `EUR ${shipmentData?.generalOptions?.minimum_order_weight?.shippingNewCustomer
                          ?.toFixed(2)
                          ?.replace(
                            ".",
                            ","
                          )} Versandkosten für\nBestellungen zwischen ${
                          shipmentData?.generalOptions?.minimum_order_weight
                            ?.minimumOrderWeightNewCustomer
                        } kg\nund ${
                          shipmentData?.generalOptions?.minimum_order_weight
                            ?.minimumOrderWeight - 0.5
                        } kg. Ab ${
                          shipmentData?.generalOptions?.minimum_order_weight
                            ?.minimumOrderWeight
                        } kg versandkostenfrei.`
                      : `Versandkostenfreie\nLieferung ab ${
                          shipmentData?.generalOptions?.shipmentCosts?.limits?.at(
                            0
                          )?.weightLimit
                        } kg.`
                  }
                  paddingTop={0}
                />
              </div>

              <Paragraph>
                €{" "}
                {shipping?.toLocaleString("DE-DE", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </Paragraph>
            </Row>
          )}
          {coolbox > 0 && (
            <Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Paragraph>Kühlbox</Paragraph>
                <CoolBoxInfo />
              </div>
              <Paragraph>
                €{" "}
                {coolbox?.toLocaleString("DE-DE", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </Paragraph>
            </Row>
          )}
          {parentPage !== "DataInputSubbesteller" && (
            <Row style={{ borderTop: "1px solid black", paddingTop: "5px" }}>
              <Paragraph style={{ fontWeight: 700 }}>Gesamt</Paragraph>
              <Paragraph style={{ fontWeight: 700 }}>
                € {totalCartState}
              </Paragraph>
            </Row>
          )}
          {parentPage !== "DataInputSubbesteller" && (
            <Row style={{ marginTop: "30px" }}>
              <Paragraph>Gesamtgewicht des Käses beträgt &nbsp;</Paragraph>

              <Paragraph>
                {parseFloat(cart?.totalWeight)?.toLocaleString("DE-DE", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}{" "}
                kg
              </Paragraph>
            </Row>
          )}
        </Footer>
      )}
    </>
  );
}

export default Accordion;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-right: 50px;
  gap: 10px;
  &.OrderOverview {
    padding: 0;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    padding: 0;
  }
  @media (max-width: 374px) {
    width: unset;
  }
`;
const ListHeadingWrapper = styled.div`
  display: flex;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ListWrapper = styled.div`
  /* padding: 20px 0; */
  /* min-width: 380px; */
`;

const HeadingSmall = styled.h4`
  font-family: "Montserrat";
  font-weight: 300;
  color: #4d4d4d;
  font-size: 16px;
  letter-spacing: 0.4px;
  &.OrderOverviewWithId {
    transform: translateX(10px);
    @media (max-width: ${constants.nexusPortraitWidth}px) {
      transform: unset;
      margin: 0;
    }
  }
`;

const HeadingSmallDM = styled.h4`
  font-family: "Montserrat";
  font-weight: 300;
  color: #4d4d4d;
  font-size: 16px;
  letter-spacing: 0.4px;
  transform: translateX(20px);
  margin: 0;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    transform: translateY(-20px);
    text-align: right;
  }
`;

const ListHeading = styled.div`
  width: 100%;
  padding-right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.OrderOverview {
    padding: 0;
  }
  &.OrderOverviewWithId {
    padding: 0;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    padding: 0;
  }
`;

const Paragraph = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.35;
  color: #4d4d4d;
  margin: 0;
`;

const ParagraphBold = styled(Paragraph)`
  font-weight: 500;
`;
